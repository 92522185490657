<template>
    <v-card-text class="pa-0 px-3">
      <v-data-table
          :headers="toolsDataTableHeader"
          :items="tableTools"
          :items-per-page="25"
          :options="{'sortBy':['updatedAt'],'sortDesc':[true]}"
          class="elevation-1 mt-6 transparent"
          :class="{'data-table--primary': small}"
          :loading="$wait.is('fetch tools details')"
          :footer-props="{
              itemsPerPageOptions: [5, 10, 25, 50, -1],
              itemsPerPageText: $t('perPage'),
              itemsPerPageAllText: $t('all'),
              pageText: '{0}-{1} ' + $t('from') + ' {2}'
          }"
          single-expand
          :show-expand="!small"
          :expanded.sync="toolsDataTableExpanded"
          @click:row="onClickToolRow"
          ripple
          :hide-default-header="hideDefaultHeader"
          item-class="class"
      >
        <template v-slot:[`item.data-table-expand`]="{isExpanded}">
          <v-icon :class="{'primary--text': isExpanded}">
              {{ isExpanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
          </v-icon>
        </template>
        <template v-slot:[`item.descriptionUrl`]="{item}">
            <v-hover v-if="item.descriptionUrl && item.descriptionUrl.length">
              <v-btn slot-scope="{ hover }" @click.stop icon :href="item.descriptionUrl" link target="_blank" :class="{'primary--text': hover}" text>
                <v-icon v-text="'mdi-open-in-new'" />
              </v-btn>
            </v-hover>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td class="tertiary pa-0 py-3" :colspan="headers.length">
              <v-subheader class="primary--text text-h5 font-weight-thin" v-text="item.title" />
              <div>
                <v-row no-gutters>
                  <v-col v-if="item.systemId" cols="12" md="6" lg="4" xl="3">
                    <v-list-item selectable inactive :ripple="false">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-music-accidental-sharp'" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle v-t="'settings.tool.systemId.fieldLabel'" class="caption" />
                        <v-list-item-title v-text="item.systemId" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col v-if="item.alias" cols="12" md="6" lg="4" xl="3">
                    <v-list-item selectable inactive :ripple="false">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-text-short'" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle v-t="'alias'" class="caption" />
                        <v-list-item-title v-text="item.alias" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col v-if="item.descriptionUrl" cols="12" md="6" lg="4" xl="3">
                    <v-hover>
                      <v-list-item slot-scope="{ hover }" :href="item.descriptionUrl" link target="_blank">
                        <v-list-item-icon>
                          <v-icon v-text="'mdi-open-in-new'" :class="{'primary--text': hover}" />
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-subtitle v-t="'settings.tool.descriptionUrl.fieldLabel'" :class="{'primary--text': hover}" class="caption" />
                          <v-list-item-title v-text="item.descriptionUrl" :class="{'primary--text': hover}" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-hover>
                  </v-col>
                  <v-col v-if="item.systemOwner" cols="12" md="6" lg="4" xl="3">
                    <v-list-item selectable inactive :ripple="false">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-account-star-outline'" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle v-t="'settings.tool.systemOwner.fieldLabel'" class="caption" />
                        <v-list-item-title v-text="item.systemOwner" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col v-if="useVendor && $objectPropertyExists(item, 'vendor') && $objectPropertyExists(item.vendor, 'name')" cols="12" md="6" lg="4" xl="3">
                    <v-list-item selectable inactive :ripple="false">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-warehouse'" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle v-t="'settings.tool.vendor.fieldLabel'" class="caption" />
                        <v-list-item-title v-text="item.vendor.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col v-if="item.cloud !== null" cols="12" md="6" lg="4" xl="3">
                    <v-list-item selectable inactive :ripple="false">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-cloud-outline'" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle v-t="'settings.tool.cloud.shortLabel'" class="caption" />
                        <v-list-item-title v-text="item.cloud" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col v-if="item.onPremise !== null" cols="12" md="6" lg="4" xl="3">
                    <v-list-item selectable inactive :ripple="false">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-application'" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle v-t="'settings.tool.onPremise.fieldLabel'" class="caption" />
                        <v-list-item-title v-text="item.onPremise" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col v-if="item.supplierAssessment !== null" cols="12" md="6" lg="4" xl="3">
                    <v-list-item selectable inactive :ripple="false">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-cloud-print-outline'" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle v-t="'settings.tool.supplierAssessment.shortLabel'" class="caption" />
                        <v-list-item-title v-text="item.supplierAssessment" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col v-if="item.iso27001Certified !== null" cols="12" md="6" lg="4" xl="3">
                    <v-list-item selectable inactive :ripple="false">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-certificate-outline'" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle v-t="'settings.tool.iso27001Certified.fieldLabel'" class="caption" />
                        <v-list-item-title v-text="item.iso27001Certified" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col v-if="item.lifecyclePhaseName !== null" cols="12" md="6" lg="4" xl="3">
                    <v-list-item selectable inactive :ripple="false">
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-clock-end'" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle v-t="'settings.tool.lifecycle.fieldLabel'" class="caption" />
                        <v-list-item-title v-text="item.lifecyclePhaseName" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <div v-if="item.description">
                  <v-subheader class="primary--text" v-t="'settings.tool.descriptionField.fieldLabel'" />
                  <v-icon v-text="'mdi-text-subject'" style="top:10px;" class="pl-4" />
                  <v-card-text v-if="item.description" v-html="$nl2br(item.description)" class="body-1 pt-0 mt-n5 pl-16 d-inline-block" />
                </div>
                <template v-if="item.securityControls && item.securityControls.length">
                  <v-list tile color="transparent">
                    <v-subheader class="primary--text" v-t="'settings.securityControl.headline'" />
                    <v-list-item-group>
                      <v-hover v-for="item in item.securityControls" :key="item.id">
                        <v-list-item slot-scope="{ hover }" selectable inactive :ripple="false">
                          <v-list-item-icon>
                            <v-icon v-text="'mdi-shield-lock-outline'" :class="{'primary--text': hover}" />
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle v-html="item.controlId" :class="{'primary--text': hover}" class="caption" />
                            <v-list-item-title v-html="item.title" :class="{'primary--text': hover}" />
                          </v-list-item-content>
                        </v-list-item>
                      </v-hover>
                    </v-list-item-group>
                  </v-list>
                </template>
              </div>
            </td>
        </template>
      </v-data-table>
    </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  i18n: {
      messages: {
          en: require('@/locales/Settings/en.json'),
          de: require('@/locales/Settings/de.json'),
      },
  },
  props: {
    hideDefaultHeader: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    useVendor: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      toolsDataTableExpanded: [],
      defaultTableHeader: [
          { text: this.$t('name'), value: 'title', sortable: true, small: true },
          { text: this.$t('settings.tool.systemId.fieldLabel'), value: 'systemId', sortable: true },
          { text: this.$t('settings.tool.systemOwner.fieldLabel'), value: 'systemOwner', sortable: true, optional: true },
          { text: this.$t('settings.tool.cloud.shortLabel'), value: 'cloud', sortable: true },
          { text: this.$t('settings.tool.onPremise.fieldLabel'), value: 'onPremise', sortable: true },
          { text: this.$t('settings.tool.supplierAssessment.shortLabel'), value: 'supplierAssessment', sortable: true, optional: true },
          { text: this.$t('settings.tool.iso27001Certified.fieldLabel'), value: 'iso27001Certified', sortable: true, optional: true },
          { text: this.$t('settings.tool.descriptionUrl.fieldLabel'), value: 'descriptionUrl', sortable: true },
      ],
      cloudItems: [
          {
              value: 'YES',
              title: this.$t('yes'),
          },
          {
              value: 'NO',
              title: this.$t('no'),
          },
          {
              value: 'NOT_EXEMPTED',
              title: this.$t('notExcludable'),
          },
      ],
      lifecycleItems: [
          {
              name: 'active',
              title: this.$t('settings.tool.lifecycle.items.active'),
          },
          {
              name: 'phase-out',
              title: this.$t('settings.tool.lifecycle.items.phase-out'),
          }
      ]
    }
  },
  computed: {
        ...mapGetters({
            loadedToolData: 'tools/getDetailedItems',
        }),
      toolsDataTableHeader () {
          if (this.small) {
            return this.defaultTableHeader.filter(x => x.small)
          }
          if (this.$vuetify.breakpoint.mdAndDown) {
              return this.defaultTableHeader.filter(x => !x.optional)
          }
          return this.defaultTableHeader;
      },
      tableTools () {
        return this.loadedToolData.map(x => {
          const cloudItem = this.$objectPropertyExists(x, 'cloud') && x.cloud !== null ? this.cloudItems.find(y => y.value === x.cloud) : null;
          x.cloud = this.$objectPropertyExists(cloudItem, 'title') && cloudItem.title !== null ? cloudItem.title : null;
          const lifecycleItem = this.$objectPropertyExists(x, 'lifecyclePhaseName') && x.lifecyclePhaseName !== null ? this.lifecycleItems.find(y => y.name === x.lifecyclePhaseName) : null;
          x.lifecyclePhaseName = this.$objectPropertyExists(lifecycleItem, 'title') && lifecycleItem.title !== null ? lifecycleItem.title : null;
          x.onPremise = this.$objectPropertyExists(x, 'onPremise') && x.onPremise !== null ? this.$tc('yesNo', Number(x.onPremise)) : null;
          x.supplierAssessment = this.$objectPropertyExists(x, 'supplierAssessment') && x.supplierAssessment !== null ? this.$tc('yesNo', Number(x.supplierAssessment)) : null;
          x.iso27001Certified = this.$objectPropertyExists(x, 'iso27001Certified') && x.iso27001Certified !== null ? this.$tc('yesNo', Number(x.iso27001Certified)) : null;
          x.class = 'data-table-item--primary lighten-3';
          return x;
        });
      }
  },
  methods: {
      onClickToolRow (val, props) {
        props.expand(!props.isExpanded);
      }
  }
}
</script>